<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="manquants"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      v-model="selectedTasks"
      show-select
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data>
        Aucun rapport manquant
      </template>
      <template v-slot:item.start_date="{ item }">
        {{ item.start_date | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.end_date="{ item }">
        {{ item.end_date | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:top>
        <v-toolbar  flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title">Timesheets manquants</v-toolbar-title>
          <v-spacer></v-spacer>
          <span class="mr-5 total-hours"><em class="total">Total : </em>{{ hours }} heures</span>
          <v-menu offset-y v-model="show">
            <template v-slot:activator="{ on, attrs }">
              <Button v-bind="attrs" v-on="on" class="mb-2" action_name="Autoriser" color="primary" text @clicked="showMenu" />
            </template>
            <v-list>
<!--              <v-list-item link @click="dialogConfirmExtend=true;plus_days=1">-->
<!--                <v-list-item-title>Autoriser pour 1 jours</v-list-item-title>-->
<!--              </v-list-item>-->
<!--              <v-list-item link @click="dialogConfirmExtend=true;plus_days=7" >-->
<!--                <v-list-item-title>Autoriser pour une semaine</v-list-item-title>-->
<!--              </v-list-item>-->
              <v-list-item link>
                <v-list-item-title @click="dialogConfirmIgnore = true">Ignorer</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="dialogConfirmHoliday = true">Congé</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog v-model="dialogConfirmIgnore" max-width="500px">
            <v-card>
              <v-card-title class="headline">Etes vous sûr d'ignorer ce(s) rapport(s)</v-card-title><v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-5" text @click="dialogConfirmIgnore = false">Cancel</v-btn>
                <v-btn color="secondary darken-5" text @click="handleIgnore()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogConfirmHoliday" max-width="500px">
            <v-card>
              <v-card-title class="headline">Etes vous sûr de confirmer congés sur ce(s) jour(s)</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark darken-5" text @click="dialogConfirmHoliday = false">Cancel</v-btn>
                <v-btn color="secondary darken-5" text @click="handleHoliday()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
<!--          <v-dialog v-model="dialogConfirmExtend" max-width="500px">-->
<!--            <v-card>-->
<!--              <v-card-title class="headline">Etes vous sûr d'autoriser ce(s) jour(s)?</v-card-title>-->
<!--              <v-card-actions>-->
<!--                <v-spacer></v-spacer>-->
<!--                <v-btn color="dark darken-5" text @click="dialogConfirmExtend = false">Cancel</v-btn>-->
<!--                <v-btn color="secondary darken-5" text @click="handleAuthorize(plus_days)">OK</v-btn>-->
<!--                <v-spacer></v-spacer>-->
<!--              </v-card-actions>-->
<!--            </v-card>-->
<!--          </v-dialog>-->
          <v-dialog v-model="filterDialog" max-width="836px">
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">Filtre</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete id="agent" v-model="editedItem.agent" :items="agents" label="Agent" item-text="name" return-object multiple deletable-chips small-chips />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete id="department" v-model="editedItem.department" :items="departments" label="Département" multiple deletable-chips small-chips />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pb-0" v-if="!disabledExactDate">
                      <label>Date</label>
                      <v-checkbox dense hide-details class="ma-0 pa-0 d-inline-block select-multiple-date" v-model="checkDate" @change="handleMultipleDate(true)"></v-checkbox>
                      <custom-date-picker v-model="editedItem.exact_date" />
                    </v-col>
                    <v-col cols="12" md="3" sm="6" class="ma-0 pb-0" v-if="showMultipleDates">
                      <label>Date début</label>
                      <v-checkbox dense hide-details class="ma-0 pa-0 d-inline-block select-multiple-date" v-model="checkDate" @change="handleMultipleDate(false)"></v-checkbox>
                      <custom-date-picker v-model="editedItem.start_date" />
                    </v-col>
                    <v-col cols="12" md="3" sm="6" class="ma-0 pb-0" v-if="showMultipleDates">
                      <label>Date fin</label>
                      <custom-date-picker v-model="editedItem.end_date" />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pb-0">
                      <AutoComplete  id="status" :items=prolongationStatus v-model="editedItem.status" label="Statut de la demande" item-text="name" return-object/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <Button action_name="Filtrer" color="#86CC26" @clicked="save" style="width: 220px" class="ml-5 mr-5 mb-5" :loading="loadingFilter"/>
                <span class="mb-5" style="color:#B2BBAB" @click="handleReset">Réinitialiser</span>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:extension>
            <div class="filter-block">
              <div>
                <template v-for="(agent, index) in editedItem.agent">
                  <v-chip class="ma-1" :key="agent.name" close @click:close="handleChipDelete(index, 'agent')">Agent:{{ agent.name }}</v-chip>
                </template>
                <template v-for="(department, index) in editedItem.department">
                  <v-chip class="ma-1" :key="department" close @click:close="handleChipDelete(index, 'department')">Departement:{{ department }}</v-chip>
                </template>
                <template v-if="editedItem.exact_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('exact_date')">Date:{{ editedItem.exact_date }}</v-chip>
                </template>
                <template v-if="editedItem.start_date && editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleMultipleDateDelete">Entre le :{{ editedItem.start_date }} et le : {{ editedItem.end_date }}</v-chip>
                </template>
                <template v-if="editedItem.start_date && !editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('start_date')">Aprés le :{{ editedItem.start_date }}</v-chip>
                </template>
                <template v-if="!editedItem.start_date && editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('end_date')">Avant le :{{ editedItem.end_date }}</v-chip>
                </template>
                <template v-if="editedItem.status">
                  <v-chip class="ma-1" close @click:close="handleExactChipDelete('status')">Status :{{ editedItem.status.name }}</v-chip>
                </template>
              </div>
              <div>
                <v-chip  class="chip-delete">
                  <v-icon @click="filterDialog = true" outlined>mdi-filter</v-icon>
                </v-chip>
              </div>
            </div>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.name="{ item }">
        <Avatar :item="item" />
        <span class="ml-4">{{ item.name }}</span>
      </template>
      <template v-slot:item.time_sheet_id="{ item }">
        <v-icon v-if="item.time_sheet_id == null" class="outlined inactive-status" >mdi-close</v-icon>
        <v-icon v-if="item.time_sheet_id != null" class="outlined active-status" >mdi-check</v-icon>
      </template>
      <template v-slot:item.total="{ item }">{{ item.total | moment_time }}</template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Button, Avatar, AutoComplete, CustomDatePicker } from "@/components/base";
// eslint-disable-next-line no-unused-vars
import { getAllTimeSheetManquantByAdmin, updateTimeSheet, ignoreTimeSheet, holidayTimeSheet } from "@/services/timesheet.api";
import { getAllActiveUsers } from "@/services/users.api";
import departments from "@/assets/data/departments.json";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";

export default {
  components: { Button, Avatar, AutoComplete, CustomDatePicker },
  mixins: [infinityScrollMixin],
  data: () => ({
    disabledExactDate:false,
    loadingFilter: false,
    showMultipleDates: false,
    checkDate: false,
    total: 0,
    options: {},
    plus_days: 0,
    departments: departments,
    text: "",
    filterDialog: false,
    editedItem: {},
    agents: [],
    selectedTasks: [],
    btnItems: [ { title: "Autoriser pour 1 jour" }, { title: "Autoriser pour une semaine" }, { title: "Ignorer" }],
    totalDays: 0,
    dialog: false,
    dialogConfirmIgnore: false,
    dialogConfirmHoliday: false,
    // dialogConfirmExtend: false,
    prolongationStatus: [
      {
        id: 0,
        name: "Non demandé"
      },
      {
        id: 1,
        name: "Demandé"
      }
    ],
    headers: [
      {
        text: "Agent",
        align: "start",
        sortable: true,
        value: "name"
      },
      { text: "Statut de la demande", value: "time_sheet_id", align: "center" },
      { text: "Date du rapport", value: "start_date" },
      { text: "Date d'expiration ", value: "end_date" },
      { text: "Total", value: "total" }
      // { text: "Actions", value: "actions", sortable: false }
    ],
    manquants: [],
    pageNumber: 1,
    last_page: 0,
    show: false,
    menu: false
  }),
  computed: {
    hours() {
      return this.totalDays * 24;
    }
  },
  watch: {
    options: {
      handler () {
        this.editedItem.order_by = this.options.sortBy[0]
        this.editedItem.order_by_direction = this.options.sortDesc[0]
        this.manquants = []
        this.pageNumber = 1
        getAllTimeSheetManquantByAdmin(this.pageNumber, this.editedItem).then(response => {
          this.manquants = response.data.data;
          this.last_page = response.data.last_page;
          this.totalHours = response.data.count;
          this.total=response.data.total
        });
      },
      deep: true,
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true) {
        this.pageNumber += 1;
        getAllTimeSheetManquantByAdmin(this.pageNumber, this.editedItem).then(response => {
          for (const i in response.data.data) {
            this.manquants.push(response.data.data[i]);
            this.totalHours += parseFloat(this.manquants[i].total);
          }
        });
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    handleExactChipDelete(key) {
      this.editedItem[key] = null
      this.save();
    },
    handleMultipleDateDelete() {
      this.editedItem["start_date"] = "";
      this.editedItem["end_date"] = "";
      this.save();
    },
    handleMultipleDate(status) {
      if (status === true) {
        this.editedItem["exact_date"] = "";
      } else {
        this.editedItem["start_date"] = "";
        this.editedItem["end_date"] = "";
      }
      this.showMultipleDates = !this.showMultipleDates;
      this.disabledExactDate = !this.disabledExactDate;
    },
    handleDateDelete(key) {
      this.editedItem[key] = "";
      this.save();
    },
    close() {
      this.filterDialog = false;
    },
    handleChipDelete(index, key) {
      this.editedItem[key].splice(index, 1);
      this.save();
    },
    handleReset() {
      this.editedItem = {};
      this.save();
    },
    showMenu() {
      this.$nextTick(() => {
        this.show = true;
      });
    },
    handleHoliday() {
      var selectedHolidays = { id: [] };
      for (let i in this.selectedTasks) {
        selectedHolidays.id.push(this.selectedTasks[i].id);
      }
      holidayTimeSheet(selectedHolidays).then(() => {
        this.$swal.fire({ title: "Rapport ajouté aux congés !", });
        this.getAllTimeSheetsMissing();
        this.dialogConfirmHoliday = false
      });
    },
    handleIgnore() {
      var selectedIgnored = { id: [] };
      for (let i in this.selectedTasks) {
        selectedIgnored.id.push(this.selectedTasks[i].id);
      }
      ignoreTimeSheet(selectedIgnored).then(() => {
        this.$swal.fire({ title: "Rapport(s) ignoré(s) avec succés !" });
        this.getAllTimeSheetsMissing();
        this.dialogConfirmIgnore = false;
      });
    },
    // handleAuthorize(dayNumber) {
    //   var selectedExtanded = { id: [], plus_day: 0 };
    //   for (let i in this.selectedTasks) {
    //     selectedExtanded.id.push(this.selectedTasks[i].id);
    //   }
    //   selectedExtanded.plus_day = dayNumber;
    //   updateTimeSheet(selectedExtanded).then(() => {
    //     this.dialogConfirmExtend = false
    //     this.$swal.fire({ title: "Rapport(s)  prolongé(s) avec succès" });
    //     this.getAllTimeSheetsMissing();
    //   });
    // },
    getAllTimeSheetsMissing() {
      getAllTimeSheetManquantByAdmin(this.pageNumber, this.editedItem).then(response => {
        this.manquants = response.data.data;
        this.last_page = response.data.last_page;
        this.totalDays = response.data.total
      });
    },
    save() {
      this.loadingFilter=true;
      this.pageNumber = 1;
      getAllTimeSheetManquantByAdmin(this.pageNumber, this.editedItem).then(response => {
        this.loadingFilter=false;
        this.manquants = response.data.data;
        this.last_page = response.data.last_page;
          this.totalDays = response.data.total;
        this.filterDialog = false;
      });
    },
    initialize() {
      this.getAllTimeSheetsMissing();
      getAllActiveUsers().then(response => {
        this.agents = response.data;
      });
    }
  }
};
</script>
